import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";

import { GreyDark, WhiteSmoke } from "assets/colors";

type TStyleParams = {
  columnWidth: number;
  rowHeight: number;
};
const useStyles = makeStyles({
  container: ({ columnWidth }: TStyleParams) => ({
    gridTemplateColumns: `${columnWidth}px 1fr`,
    height: "calc(100vh - 200px)",
    backgroundColor: "white",
    display: "grid",
    overflowX: "auto",
    overflowY: "auto",
    position: "relative",
  }),
  left: ({ rowHeight }: TStyleParams) => ({
    display: "grid",
    gridAutoRows: `${rowHeight}px`,
    position: "sticky",
    background: "white",
    width: "160px",
    top: 0,
    left: 0,
    borderRight: `1px solid ${WhiteSmoke}`,
    zIndex: 3,
  }),
  leftItem: ({ rowHeight }: TStyleParams) => ({
    height: "100%",
    borderBottom: `1px solid ${WhiteSmoke}`,
    display: "grid",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 500,
    paddingLeft: "8px",
    minHeight: `${rowHeight}px`,
  }),
  title: ({ rowHeight }: TStyleParams) => ({
    position: "sticky",
    top: 0,
    background: "white",
    height: `${rowHeight}px`,
  }),
  right: ({ rowHeight }: TStyleParams) => ({
    display: "grid",
    gridTemplateRows: `${rowHeight}px 1fr`,
    width: "100%",
  }),
  rightTop: ({ columnWidth }: TStyleParams) => ({
    borderBottom: `1px solid ${WhiteSmoke}`,
    display: "grid",
    gridAutoColumns: `${columnWidth}px`,
    gridAutoFlow: "column",
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 2,
  }),
  rightItem: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: 500,
    borderLeft: `1px solid ${WhiteSmoke}`,
    borderRight: `1px solid ${WhiteSmoke}`,
  },
  background: {
    backgroundColor: "white",
  },
  body: ({ rowHeight }: TStyleParams) => ({
    display: "grid",
    gridAutoRows: `${rowHeight}px`,
  }),
  bodyItem: {
    borderBottom: `1px solid ${WhiteSmoke}`,
  },
});

type TStickyTableColumnItem = {
  key: string;
  label: string;
};
type TSitckTableRowItem = {
  key: string;
  title: string;
  description?: string;
};
interface IStickyTableProps extends TStyleParams {
  columns: Array<TStickyTableColumnItem>;
  rows: Array<TSitckTableRowItem>;
  title: string;
}
const StickyTable: React.FC<IStickyTableProps> = ({
  columnWidth,
  rowHeight,
  columns,
  rows,
  title,
  children,
}) => {
  const classes = useStyles({
    columnWidth,
    rowHeight,
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.left}>
        <Box className={classNames(classes.leftItem, classes.title)}>
          {title}
        </Box>
        {_.map(rows, (row) => (
          <Box
            key={row.key}
            className={classes.leftItem}
            display={"grid"}
            gridTemplateRows={"1fr 1fr"}
            gridRowGap={"4px"}
          >
            <Box alignSelf={"end"}>{row.title}</Box>
            <Box color={GreyDark} alignSelf={"start"}>
              {row.description}
            </Box>
          </Box>
        ))}
      </Box>

      <Box className={classes.right}>
        <Box className={classes.rightTop}>
          {_.map(columns, (col) => (
            <Box key={col.key} className={classes.rightItem}>
              {col.label}
            </Box>
          ))}
        </Box>

        <Box className={classes.body}>{children}</Box>
      </Box>
    </Box>
  );
};

export const StickyTableRowItem: React.FC<{}> = ({ children }) => {
  const classes = useStyles({
    columnWidth: 0,
    rowHeight: 0,
  });
  return <Box className={classes.bodyItem}>{children}</Box>;
};

export default StickyTable;
