import React from "react";

import { Box, Fade, makeStyles } from "@material-ui/core";
import { CalendarEventStatus, TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { Moment } from "moment";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import { StrokeLowEnphasis } from "assets/colors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import BoardItem, { IBoardItemProps } from "./BoardItem";
import { BOX_HEIGHT } from "./Calendar";
import {
  calculateMarginTop,
  getEventColPosition,
  getEventMarginLeft,
  getEventRowPosition,
  getMaxEventWidth,
} from "./utils/boardItemPositionUtils";
import { getBoardSlotDateTimeString } from "./utils/getBoardSlotDateTimeString";
import { getCalendarWorkingHours, getTimesOfDay } from "./utils/getTimesOfDay";

interface IBoardStylesProps {
  TIMES_OF_DAY: string[];
  BOX_HEIGHT: number;
  orientation: "horizontal" | "vertical";
}
const useStyles = makeStyles({
  boardContainer: ({
    TIMES_OF_DAY,
    BOX_HEIGHT,
    orientation,
  }: IBoardStylesProps) => {
    return {
      display: "grid",
      ...(orientation === "vertical" && {
        gridTemplateColumns: "initial",
        gridTemplateRows: `repeat(${_.size(TIMES_OF_DAY) * 2}, ${
          BOX_HEIGHT / 2
        }px)`,
        borderRight: `solid 2px ${StrokeLowEnphasis}`,
        gridAutoFlow: "row",
      }),
      ...(orientation === "horizontal" && {
        gridTemplateColumns: `repeat(${_.size(TIMES_OF_DAY)}, 1fr)`,
        gridTemplateRows: "initial",
        gridAutoFlow: "column",
      }),
      width: "100%",
    };
  },
  box: ({ BOX_HEIGHT, orientation }: IBoardStylesProps) => ({
    ...(orientation === "vertical" && {
      borderBottom: `2px solid ${StrokeLowEnphasis}`,
    }),
    ...(orientation === "horizontal" && {}),
    height: `${BOX_HEIGHT}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // Center vertically
    background: "none",
  }),
});

export type TEventPlaceHolder = Pick<
  TCalendarEvent,
  "start_time" | "end_time" | "repetition" | "assigned_to"
> & {
  component: React.ReactNode;
};

export interface IBoardProps
  extends Pick<IBoardItemProps, "onClickApproveEvent" | "onClickDeclineEvent"> {
  events: TCalendarEvent[];
  eventPlaceHolders?: TEventPlaceHolder[];
  activeBoardItems?: string[];
  boardId: string;
  boardContainerProps?: React.ComponentProps<"div">;
  draggable?: boolean;
  onClickTimeSlot?: (date: Moment) => void;
  onEditEvent: (event: TCalendarEvent) => void;
  onClickDeleteEvent: (event: TCalendarEvent) => void;
  orientation?: "horizontal" | "vertical";
}
function Board({
  onEditEvent,
  onClickDeleteEvent,
  events,
  boardId,
  draggable = true,
  onClickTimeSlot,
  eventPlaceHolders = [],
  activeBoardItems = [],
  onClickApproveEvent,
  onClickDeclineEvent,
  orientation = "vertical",
}: Readonly<IBoardProps>) {
  const client = useSelector(getSelectedClient);
  const { moment } = useMomentTimeZone();
  const TIMES_OF_DAY = getTimesOfDay(client, moment);
  const classes = useStyles({
    TIMES_OF_DAY,
    BOX_HEIGHT: orientation === "horizontal" ? 70 : 55,
    orientation,
  });

  const { minEventStartTime } = getCalendarWorkingHours(client, moment);
  return (
    <Droppable droppableId={boardId} key={boardId} isDropDisabled={true}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            data-testid={`board-${boardId}`}
          >
            <Box className={classes.boardContainer} position={"relative"}>
              <Box
                height={
                  orientation === "vertical"
                    ? `calc(${TIMES_OF_DAY.length} * ${BOX_HEIGHT}px)`
                    : "70px"
                }
                position={"absolute"}
                top={0}
                width={"100%"}
                {...(orientation === "horizontal"
                  ? {
                      display: "grid",
                      gridAutoFlow: "column",
                      gridColumn: "160px",
                      gridTemplateColumns: `repeat(${_.size(
                        TIMES_OF_DAY
                      )}, 1fr)`,
                    }
                  : {})}
              >
                {_.times(TIMES_OF_DAY.length, (index) => {
                  const { dateFormatted, momentDate } =
                    getBoardSlotDateTimeString({
                      day: boardId.includes("_")
                        ? boardId.split("_")[0]
                        : boardId,
                      time: TIMES_OF_DAY[index],
                      moment,
                      client,
                    });

                  const droppableIdAdornment =
                    orientation === "horizontal"
                      ? "_" + boardId.split("_")[1]
                      : "";
                  return (
                    <Droppable
                      droppableId={dateFormatted + droppableIdAdornment}
                      key={dateFormatted}
                      ignoreContainerClipping
                    >
                      {(provided2) => (
                        <div
                          ref={provided2.innerRef}
                          style={{
                            ...(orientation === "horizontal" && {
                              borderRight: `2px solid ${StrokeLowEnphasis}`,
                            }),
                          }}
                          {...provided2.droppableProps}
                        >
                          <Box
                            className={classes.box}
                            key={index}
                            width={"100%"}
                            maxWidth={
                              orientation === "horizontal" ? "160px" : "unset"
                            }
                            onClick={() =>
                              onClickTimeSlot && onClickTimeSlot(momentDate)
                            }
                            data-testid={`timeslot-${dateFormatted}`}
                          >
                            {provided2.placeholder}
                          </Box>
                        </div>
                      )}
                    </Droppable>
                  );
                })}
              </Box>
              {/* DRAW EVENTS */}
              {_.map(_.reverse(events), (event, index) => {
                const active = activeBoardItems.includes(event?.id || "");
                return (
                  <Draggable
                    key={event.id}
                    draggableId={event.id as string}
                    index={index}
                    isDragDisabled={
                      !draggable ||
                      event.status === CalendarEventStatus.COMPLETED
                    }
                  >
                    {(provided1) => {
                      const smallBoardItem =
                        moment(event.end_time).diff(
                          event.start_time,
                          "minutes"
                        ) <= 30;
                      return (
                        <div
                          ref={provided1.innerRef}
                          {...provided1.draggableProps}
                          {..._.omit(provided1.dragHandleProps, ["style"])}
                          className={classes.box}
                          style={{
                            ...(orientation === "vertical" && {
                              width: "100%",
                              height: "97%",
                              background: "white",
                              borderBottom: "none",
                              zIndex: 5,
                              gridRow: getEventRowPosition(
                                event,
                                moment
                                  .utc(event.start_time)
                                  .hour(minEventStartTime.utc().hour()),
                                moment
                              ),
                              paddingTop: !smallBoardItem ? "5px" : "3px",
                              paddingBottom: !smallBoardItem ? "5px" : "0px",
                              position: "absolute",
                              marginTop: calculateMarginTop(event, moment),
                            }),
                            ...(orientation === "horizontal" && {
                              zIndex: 1,
                              gridColumn: getEventColPosition(
                                event,
                                moment
                                  .utc(event.start_time)
                                  .hour(minEventStartTime.utc().hour()),
                                moment
                              ),
                              width: `100%`,
                              height: "60px",
                              paddingTop: "8px",
                              marginLeft:
                                getEventMarginLeft(event, moment) + "px",
                              maxWidth: getMaxEventWidth(event, moment) + "px",
                              position: "absolute",
                            }),
                            ...provided1.draggableProps.style,
                          }}
                          data-testid={`event-${event.id}`}
                        >
                          <BoardItem
                            event={event}
                            boxProps={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              textAlign: "center",
                              minWidth: undefined,
                            }}
                            onEditEvent={onEditEvent}
                            onClickDelete={onClickDeleteEvent}
                            onClickApproveEvent={onClickApproveEvent}
                            onClickDeclineEvent={onClickDeclineEvent}
                            active={active}
                            boardOrientation={orientation}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {/* DRAW PLACEHOLDERS */}
              {_.map(eventPlaceHolders, (placeholder, index) => {
                const smallBoardItem =
                  moment(placeholder.end_time).diff(
                    placeholder.start_time,
                    "minutes"
                  ) <= 30;
                return (
                  <Fade
                    in
                    key={
                      //only re-animate if start_time/end_time change
                      placeholder.start_time?.toString() +
                      placeholder.end_time?.toString()
                    }
                  >
                    <Box
                      key={index}
                      className={classes.box}
                      style={{
                        ...(orientation === "vertical" && {
                          width: "100%",
                          height: "97%",
                          background: "white",
                          borderBottom: "none",
                          zIndex: 5,
                          gridRow: getEventRowPosition(
                            _.pick(placeholder, [
                              "start_time",
                              "end_time",
                            ]) as TCalendarEvent,
                            moment
                              .utc(placeholder.start_time)
                              .hour(minEventStartTime.utc().hour()),
                            moment
                          ),
                          paddingTop: !smallBoardItem ? "5px" : "3px",
                          paddingBottom: !smallBoardItem ? "5px" : "0px",
                          position: "absolute",
                          marginTop: calculateMarginTop(event, moment),
                        }),
                        ...(orientation === "horizontal" && {
                          zIndex: 0,
                          gridColumn: getEventColPosition(
                            _.pick(placeholder, [
                              "start_time",
                              "end_time",
                            ]) as TCalendarEvent,
                            moment
                              .utc(placeholder.start_time)
                              .hour(minEventStartTime.utc().hour()),
                            moment
                          ),
                          width: `100%`,
                          height: "60px",
                          paddingTop: "8px",
                          marginLeft:
                            getEventMarginLeft(placeholder as any, moment) +
                            "px",
                          maxWidth:
                            getMaxEventWidth(placeholder as any, moment) + "px",
                        }),
                      }}
                    >
                      {placeholder.component}
                    </Box>
                  </Fade>
                );
              })}
            </Box>
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
}

export default Board;
