import {
  CUSTOM_FIELD_TYPE,
  META_EXPRESSION_SCOPE,
  PostgresTypes,
  TRIGGER_EVENT,
} from "fieldpro-tools";
import _ from "lodash";

import { IOption } from "model/application/components";
import { ITransformation } from "model/entities/Job";
import { IList } from "model/entities/List";
import { IWebhook } from "model/entities/Webhook";
import { IActivity } from "model/entities/Workflow";
import { activityNeedsCustomer } from "utils/businessUtils";

import { FieldTypes, getFieldsOfFieldType } from "./fieldOptions";

export interface ICustomField {
  tag: string;
  type: CUSTOM_FIELD_TYPE;
  options?: IOption[];
  list_id?: string;
}

const convertStringToCustomField = (input: string): CUSTOM_FIELD_TYPE => {
  let result: CUSTOM_FIELD_TYPE = CUSTOM_FIELD_TYPE.TEXT;
  switch (input.toLowerCase()) {
    case "string":
    case "text":
      result = CUSTOM_FIELD_TYPE.TEXT;
      break;
    case "boolean":
      result = CUSTOM_FIELD_TYPE.BOOLEAN;
      break;
    case "date":
      result = CUSTOM_FIELD_TYPE.DATE_PICKER;
      break;
    case "decimal":
    case "number":
      result = CUSTOM_FIELD_TYPE.DECIMAL;
      break;
    case "integer":
      result = CUSTOM_FIELD_TYPE.INTEGER;
      break;
    case "array": // will not get returned in ME
    case "object":
      break;
  }
  return result;
};

const convertToCustomField = (input: string): CUSTOM_FIELD_TYPE => {
  let result: CUSTOM_FIELD_TYPE = CUSTOM_FIELD_TYPE.TEXT;
  switch (input) {
    case PostgresTypes.BOOLEAN:
      result = CUSTOM_FIELD_TYPE.BOOLEAN;
      break;
    case PostgresTypes.BIGINT:
    case PostgresTypes.INTEGER:
      result = CUSTOM_FIELD_TYPE.INTEGER;
      break;
    case PostgresTypes.NUMERIC:
      result = CUSTOM_FIELD_TYPE.DECIMAL;
      break;
    case PostgresTypes.TEXT:
      result = CUSTOM_FIELD_TYPE.TEXT;
      break;
    case PostgresTypes.TIMESTAMP:
      result = CUSTOM_FIELD_TYPE.DATE_PICKER;
      break;
    default:
      result = input.toUpperCase() as CUSTOM_FIELD_TYPE;
  }

  return result;
};

export const getSchemaForMetaExpression = (
  // TODO: only "list" and "activity" should be required here -> we find the right ones in the caller
  activities: IActivity[],
  lists: IList[],
  transformations: ITransformation[],
  webhooks?: IWebhook[],
  scope?: META_EXPRESSION_SCOPE,
  currentRessourceId?: string,
  currentSubRessourceId?: string, // in case of a matrix
  numberIndex?: number, // if we are in an activity, only the previous questions should be selectable
  type?: CUSTOM_FIELD_TYPE,
  includeMatrix?: boolean,
  triggerEvent?: TRIGGER_EVENT
): ICustomField[] => {
  let elements: ICustomField[] = [];
  if (!scope) return elements;
  if (
    scope === META_EXPRESSION_SCOPE.ACTIVITY_REPORT ||
    scope === META_EXPRESSION_SCOPE.WORKFLOW_REPORT
  ) {
    elements = getSchemaForReportMetaExpression(
      activities,
      currentRessourceId,
      currentSubRessourceId,
      numberIndex,
      lists
    );
  } else if (
    scope === META_EXPRESSION_SCOPE.TRANSFORMATION ||
    scope === META_EXPRESSION_SCOPE.INTEG_RECORD
  ) {
    elements = getSchemaForActivityMetaExpression(
      transformations,
      currentRessourceId
    );
  } else if (scope === META_EXPRESSION_SCOPE.ITEM) {
    elements = getSchemaForItemMetaExpression(lists, currentRessourceId);
  } else if (scope === META_EXPRESSION_SCOPE.CUSTOMER) {
    elements = getSchemaForCustomerMetaExpression(lists);
  } else if (scope === META_EXPRESSION_SCOPE.MATRIX_CELL) {
    elements = getSchemaForMatrixCellMetaExpression(
      activities,
      currentRessourceId,
      currentSubRessourceId
    );
  } else if (scope === META_EXPRESSION_SCOPE.ITEM_CELL) {
    elements = getSchemaForItemCellMetaExpression(
      activities,
      lists,
      currentRessourceId,
      currentSubRessourceId
    );
  } else if (scope === META_EXPRESSION_SCOPE.TRIGGER_EVENT) {
    elements = getSchemaForTriggerEventMetaExpression(
      triggerEvent,
      webhooks,
      lists,
      currentRessourceId
    );
  }
  if (type) {
    elements = elements.filter((e) => {
      // sometimes, regardless of the type, we want to include matrix in the schema
      if (e.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST && includeMatrix)
        return true;
      return e.type === type;
    });
  }
  return elements;
};

const getSchemaForItemCellMetaExpression = (
  activities: IActivity[],
  lists: IList[],
  currentRessourceId?: string,
  currentSubRessourceId?: string
): ICustomField[] => {
  let result: ICustomField[] = [];
  // the current ressource id is supposed to be an activity id.
  const activity = activities.find((a) => a.id === currentRessourceId);
  if (activity) {
    const matrixQuestion = activity.questionnaire.questions.find(
      (q) => q.tag === currentSubRessourceId
    );
    if (matrixQuestion) {
      const list = lists.find((l) => l.id === matrixQuestion.list_id);
      if (list) {
        result = list.schema.map((att) => ({
          tag: att.column_tag,
          type: att.type,
          options: att.options,
          list_id: att.list_id,
        }));
      }
    }
  }
  return result;
};

const getSchemaForMatrixCellMetaExpression = (
  activities: IActivity[],
  currentRessourceId?: string,
  currentSubRessourceId?: string
): ICustomField[] => {
  let result: ICustomField[] = [];
  // the current ressource id is supposed to be an activity id.
  const activity = activities.find((a) => a.id === currentRessourceId);
  if (activity) {
    const matrixQuestion = activity.questionnaire.questions.find(
      (q) => q.tag === currentSubRessourceId
    );
    if (matrixQuestion?.matrix) {
      result = matrixQuestion.matrix.typed_questions;
    }
  }
  return result;
};

const getSchemaForCustomerMetaExpression = (lists: IList[]): ICustomField[] => {
  let result: ICustomField[] = [];
  // we have to select the customer list
  const customerList = lists.find((l) => l.id === "customer");
  if (customerList) {
    result = customerList.schema.map((att) => ({
      tag: att.column_tag,
      type: att.type,
      options: att.options,
      list_id: att.list_id,
    }));
  }
  return result;
};

const getSchemaForItemMetaExpression = (
  lists: IList[],
  currentRessourceId?: string
): ICustomField[] => {
  let result: ICustomField[] = [];
  // the current ressource id is supposed to be a list id
  const list = lists.find((l) => l.id === currentRessourceId);
  if (list) {
    result = list.schema.map((att) => ({
      tag: att.column_tag,
      type: att.type,
      options: att.options,
      list_id: att.list_id,
    }));
  }
  return result;
};

export const getSchemaOfFieldType = (fieldType: FieldTypes): ICustomField[] => {
  const fieldConfig = getFieldsOfFieldType(fieldType);

  if (!fieldConfig) {
    return [];
  }

  const result: ICustomField[] = [];
  for (const [type, fields] of Object.entries(fieldConfig)) {
    fields.forEach((field) => {
      result.push({ tag: field, type: convertStringToCustomField(type) });
    });
  }
  return result;
};

export const getSchemaForTriggerEventMetaExpression = (
  event_type: TRIGGER_EVENT | undefined,
  webhooks?: IWebhook[],
  lists?: IList[],
  currentRessourceId?: string
): ICustomField[] => {
  switch (event_type) {
    case TRIGGER_EVENT.WEBHOOK_EVENT:
      if (!webhooks) return [];
      let result: ICustomField[] = [];
      const webhook = webhooks.find((w) => w.id === currentRessourceId);
      if (webhook) {
        if (!webhook.response_schema) webhook.response_schema = [];
        result = webhook.response_schema.map((att) => ({
          tag: att.name,
          type: convertStringToCustomField(att.type),
        }));
      }
      return result;
    case TRIGGER_EVENT.FIELDPRO_CREATE_MOBILE_USER:
      return getSchemaOfFieldType(FieldTypes.USER_FIELDS);
    case TRIGGER_EVENT.FIELDPRO_CREATE_ITEM:
    case TRIGGER_EVENT.FIELDPRO_EDIT_ITEM:
      return getSchemaForItemMetaExpression(lists ?? [], currentRessourceId);
    default:
      return [];
  }
};

const getSchemaForActivityMetaExpression = (
  transformations: ITransformation[],
  currentRessourceId?: string
): ICustomField[] => {
  let result: ICustomField[] = [];
  const transformation = transformations.find(
    (t) => t.id === currentRessourceId
  );
  if (transformation) {
    result = transformation.destination_columns.map((e) => ({
      tag: e.column_name,
      type: convertToCustomField(e.column_type),
    }));
  }
  return result;
};

const getSchemaForReportMetaExpression = (
  activities: IActivity[],
  currentRessourceId?: string,
  currentSubRessourceId?: string,
  numberIndex?: number, // if we are in an activity, only the previous questions should be selectable
  lists?: IList[]
) => {
  let result: ICustomField[] = [];
  // the current ressource id is supposed to be an activity id
  const activity = activities.find((a) => a.id === currentRessourceId);
  if (activity) {
    if (currentSubRessourceId) {
      const matrixIndex = _.findIndex(activity.questionnaire.questions, {
        tag: currentSubRessourceId,
      });
      result = [
        ..._.filter(
          activity.questionnaire.questions,
          (q) => numberIndex === undefined || q.index < matrixIndex
        ),
      ];
      const matrixQuestion = _.find(
        activity.questionnaire.questions,
        (q) => q.tag === currentSubRessourceId
      );
      if (matrixQuestion?.matrix) {
        result = _.concat(
          result,
          _.filter(
            matrixQuestion.matrix.typed_questions,
            (q) => numberIndex === undefined || q.index < numberIndex
          )
        );
      }
    } else {
      result = activity.questionnaire.questions.filter(
        (q) => numberIndex === undefined || q.index < numberIndex
      );
    }
    if (activityNeedsCustomer(activity)) {
      const customerList = lists?.find((l) => l.id === "customer");
      const customerFields = customerList?.schema.map(
        (att): IOption<string> => ({
          key: att.column_tag,
          label: att.column_tag,
        })
      );
      result.push({
        tag: "_selected_customer",
        type: CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
        options: customerFields,
      });
    }
  }
  return result;
};
