import _ from "lodash";

import {
  buildDateFilter,
  buildListItemFilter,
  buildMoreFilter,
  buildMultipleChoiceFilter,
  buildNumberFilter,
  buildSingleChoiceFilter,
  buildTeamsAndHierarchyFilter,
  buildTextFilter,
  getStartAndEndDateFromDateType,
} from "components/Filter/prepareFiltersUtils";
import { FILTER_TAG } from "components/Filter/TypeFilter";
import { isDisplayed } from "containers/lists/subcategories/prepareFilters";
import { getLangObject } from "lang/utils";
import { ACTION_CODE } from "model/application/ActionCode";
import { IFilter } from "model/application/Filter";
import { DEFAULT_DATE_FILTER, IClient } from "model/entities/Client";
import {
  DASHBOARD_TYPE,
  DEFAULT_DASHBOARD,
  IDashboard,
} from "model/entities/Dashboard";
import { IMetaHierarchyDependency } from "model/entities/HierarchyDependency";
import { IList } from "model/entities/List";
import { ITableOptimization } from "model/entities/TableOptimization";
import { ITeamSelector } from "model/entities/Team";
import { IMobileUser } from "model/entities/User";
import { CUSTOM_FIELD_TYPE, IActivity } from "model/entities/Workflow";

import { IKPI } from "./../../../../node_modules/fieldpro-tools/src/types/dashboards";
import {
  isFilterInDashboard,
  isFilterInKpi,
  isListAttrInDashboardFilter,
  isTagPresentInMultipleLists,
} from "./OptimetriksDashboardScreenUtils";

const defaultFilterTags = [
  FILTER_TAG.DATE,
  FILTER_TAG.TEAMS,
  FILTER_TAG.USERS,
  FILTER_TAG.USERS.substring(1),
  FILTER_TAG.MOBILE_ROLES,
];

export const prepareFilters = (
  dashboard: IDashboard,
  client: IClient,
  workflows: { id: string; name: string }[],
  lists: IList[],
  teams: ITeamSelector[],
  users: IMobileUser[],
  mobileUserBusinessRoles: { key: string; label: string }[],
  dateType: DEFAULT_DATE_FILTER,
  allTableOptimizations: ITableOptimization[]
): IFilter[] => {
  const params = dashboard.query;
  let filters: IFilter[] = [];

  const lang = getLangObject();

  switch (dashboard.id) {
    case DEFAULT_DASHBOARD.RECAP_REPORT: {
      // if recap report, date filter
      filters.push(
        buildDateFilter(lang.components.filters.date, FILTER_TAG.DATE, dateType)
      );
      break;
    }
    case "_user_report": {
      // if user report, date filter + team filter + workflows filter
      filters.push(
        buildDateFilter(lang.components.filters.date, FILTER_TAG.DATE, dateType)
      );
      filters = filters.concat(
        buildTeamsAndHierarchyFilter(teams, client, params[FILTER_TAG.TEAMS])
      );
      filters.push(buildWorkflowsFilter(workflows));
      break;
    }
    case DEFAULT_DASHBOARD.MONTHLY_SUBMISSIONS:
    case DEFAULT_DASHBOARD.WEEKLY_SUBMISSIONS: {
      // no filter for this dashboard
      break;
    }
    case DEFAULT_DASHBOARD.GPS_TRACKING: {
      dateType = DEFAULT_DATE_FILTER.YESTERDAY;
      // if gps tracking report, date filter (single day) + user filter
      const dateFilter = buildDateFilter(
        lang.components.filters.date,
        FILTER_TAG.DATE,
        dateType
      );
      // change the single selection behavior
      dateFilter.singleSelection = true;
      const { startDate, endDate } = getStartAndEndDateFromDateType(
        DEFAULT_DATE_FILTER.YESTERDAY
      );
      dateFilter.value = {
        startDate: startDate,
        endDate: endDate,
        dateType: dateType,
      };
      filters.push(dateFilter);
      filters.push(buildUsersFilter(users));
      filters = filters.concat(
        buildTeamsAndHierarchyFilter(teams, client, params[FILTER_TAG.TEAMS])
      );
      break;
    }
    case DEFAULT_DASHBOARD.USAGE:
    case DEFAULT_DASHBOARD.USAGE_PER_CLIENT: {
      // if usage report, date filter
      filters.push(
        buildDateFilter(lang.components.filters.date, FILTER_TAG.DATE, dateType)
      );
      break;
    }
    case DEFAULT_DASHBOARD.LOG_REPORT: {
      // if log report, date filter + email filter + phone filter + user name filter + actionCode filter
      filters.push(
        buildDateFilter(lang.components.filters.date, FILTER_TAG.DATE, dateType)
      );
      filters.push(
        buildTextFilter(lang.components.filters.email, "user_email")
      );
      filters.push(
        buildTextFilter(lang.components.filters.phone, "user_phone")
      );
      filters.push(buildTextFilter(lang.components.filters.name, "user_name"));
      filters.push(buildActionCodeFilter());
      // according to the value of action_code, add workflow or list filter
      if (
        params.action_code &&
        params.action_code.find(
          (ac: string) => ac.includes("WORKFLOW") || ac.includes("SUBMISSION")
        )
      ) {
        filters.push(buildWorkflowsFilter(workflows));
      }
      if (
        params.action_code &&
        params.action_code.find(
          (ac: string) => ac.includes("LIST") || ac.includes("ITEM")
        )
      ) {
        filters.push(buildListsFilter(lists));
      }
      break;
    }
    default: {
      // default behavior: we search on the kpis queries which filters are in use
      if (isFilterInDashboard("workflows", dashboard)) {
        // add workflows filters
        filters.push(buildWorkflowsFilter(workflows));
      }
      if (dashboard.date_filter || isFilterInDashboard("date", dashboard)) {
        // add the date filter
        filters.push(
          buildDateFilter(
            lang.components.filters.date,
            FILTER_TAG.DATE,
            dateType
          )
        );
      }

      if (dashboard.user_filter || isFilterInDashboard("users", dashboard)) {
        // add the users filter
        filters.push(buildUsersFilter(users));
      }

      if (dashboard.team_filter || isFilterInDashboard("teams", dashboard)) {
        // add the team filters (either only team or team + hierarchy)
        filters = filters.concat(
          buildTeamsAndHierarchyFilter(teams, client, params[FILTER_TAG.TEAMS])
        );
      }

      if (
        dashboard.mobile_user_roles ||
        isFilterInDashboard("mobile_user_roles", dashboard)
      ) {
        filters.push(buildMobileRolesFilter(mobileUserBusinessRoles ?? []));
      }

      if (
        isFilterInDashboard("param1", dashboard) &&
        dashboard.type === DASHBOARD_TYPE.ON_LIST_ITEM
      ) {
        filters.push(
          buildListItemsFilter(lists, "param1", dashboard.list_id, true)
        );
      }
      if (dashboard.table_optimizations) {
        dashboard.table_optimizations.forEach((o: string) => {
          const table_optimization = allTableOptimizations.find(
            (to) => to.id === o && to.options && to.options.length > 0
          );
          if (table_optimization) {
            filters = filters.concat(
              buildOptimizationFilters(
                table_optimization,
                dashboard.hierarchical_level_linked_to_table_optimization,
                teams,
                client.meta_hierarchy_dependencies
              )
            );
          }
        });
      }
      if (isFilterInDashboard("attr", dashboard)) {
        const moreOptions: Array<{
          key: string;
          label: string;
        }> = [];
        const activeLists = lists.filter((l) => l.active != false);
        activeLists.forEach((al) => {
          const linkedListsIds = al.schema
            .filter((s) =>
              [
                CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST,
                CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST,
              ].includes(s.type)
            )
            .map((s) => s.list_id);
          const linkedLists = activeLists.filter((l) =>
            linkedListsIds.includes(l.id)
          );
          filters = filters.concat(
            buildListAttributeFilter(al, params, dashboard, linkedLists)
          );
          filters = filters.sort((a, b) => a.label.localeCompare(b.label));
          const listAttrFilters = al.schema
            .filter((s) => {
              const filterTag =
                s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
                  ? `${al.id}.${s.column_tag}__id`
                  : `${al.id}.${s.column_tag}`;
              const customerFilterTag =
                s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
                  ? `${s.column_tag}__id`
                  : `${s.column_tag}`;
              return (
                (al.id === "customer"
                  ? isListAttrInDashboardFilter(customerFilterTag, dashboard) ||
                    isListAttrInDashboardFilter(filterTag, dashboard)
                  : isListAttrInDashboardFilter(filterTag, dashboard)) &&
                [
                  CUSTOM_FIELD_TYPE.TEXT,
                  CUSTOM_FIELD_TYPE.PLAIN_TEXT,
                  CUSTOM_FIELD_TYPE.PHONE_NUMBER,
                  CUSTOM_FIELD_TYPE.INTEGER,
                  CUSTOM_FIELD_TYPE.DECIMAL,
                  CUSTOM_FIELD_TYPE.DATE_PICKER,
                  CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE,
                  CUSTOM_FIELD_TYPE.SINGLE_CHOICE,
                  CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST,
                  CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST,
                ].includes(s.type)
              );
            })
            .map((s) => ({
              key:
                s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
                  ? `${s.column_tag}__id`
                  : s.column_tag,
              label: s.column_name,
            }));
          moreOptions.push(...listAttrFilters);
        });
        moreOptions &&
          moreOptions.length > 0 &&
          filters.push(
            buildMoreFilter(
              _.uniqBy(
                [
                  ...filters.map((filter) => ({
                    key: filter.tag,
                    label: filter.label,
                  })),
                  ...moreOptions,
                ],
                "key"
              )
            )
          );
      }
      if (isFilterInDashboard("custom", dashboard)) {
        filters.push(...buildCustomFilter(dashboard));
      }
    }
  }
  return filters.map((f) => {
    if (f.tag && params[f.tag]) {
      f.value = params[f.tag];
    }
    if (f.tag === FILTER_TAG.MORE_FILTER) {
      f.options = _.filter(f.options, (option) => !isDisplayed(option.key, f));
    }
    f.isSecondaryFilter =
      !defaultFilterTags.includes(f.tag as any) &&
      f.tag !== FILTER_TAG.MORE_FILTER;
    return f;
  });
};

const buildUsersFilter = (users: IMobileUser[]): IFilter => {
  const lang = getLangObject();
  const userOptions = users
    .filter((user) => user.licensed)
    .map((u) => ({
      label: `${u.first_name} ${u.last_name}`,
      key: u.id,
    }));
  return buildMultipleChoiceFilter(
    userOptions,
    lang.components.filters.users,
    "users",
    []
  );
};

const buildMobileRolesFilter = (
  mobileUserBusinessRoles: { key: string; label: string }[]
): IFilter => {
  const lang = getLangObject();
  const roleOptions = mobileUserBusinessRoles;
  return buildMultipleChoiceFilter(
    roleOptions,
    lang.components.filters.mobileRoles,
    "mobile_user_roles",
    []
  );
};

export const buildListAttributeFilter = (
  list: IList,
  params: any,
  dashboard: IDashboard,
  linkedLists: IList[] = []
): IFilter[] => {
  let filters: IFilter[] = [];
  list.schema
    .filter((s) => {
      const filterTag =
        s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
          ? `${list.id}.${s.column_tag}__id`
          : `${list.id}.${s.column_tag}`;
      const customerFilterTag =
        s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
          ? `${s.column_tag}__id`
          : `${s.column_tag}`;
      const b =
        list.id === "customer"
          ? isListAttrInDashboardFilter(customerFilterTag, dashboard) ||
            isListAttrInDashboardFilter(filterTag, dashboard)
          : isListAttrInDashboardFilter(filterTag, dashboard);
      return b;
    })
    .forEach((s) => {
      const tagPresentManyTimes = isTagPresentInMultipleLists(
        s.column_tag,
        dashboard
      );
      const filterLabel = tagPresentManyTimes
        ? `${s.column_name} (${list.name})`
        : s.column_name;
      const filterTag = tagPresentManyTimes
        ? `${list.id}.${s.column_tag}`
        : s.column_tag;
      switch (s.type) {
        case CUSTOM_FIELD_TYPE.TEXT:
        case CUSTOM_FIELD_TYPE.PLAIN_TEXT:
        case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
          filters.push(buildTextFilter(filterLabel, filterTag));
          break;
        case CUSTOM_FIELD_TYPE.INTEGER:
        case CUSTOM_FIELD_TYPE.DECIMAL:
          filters.push(buildNumberFilter(`${filterLabel}`, filterTag));
          break;
        case CUSTOM_FIELD_TYPE.DATE_PICKER:
          filters.push(buildDateFilter(`${filterLabel}`, filterTag));
          break;
        case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
          filters.push(
            buildMultipleChoiceFilter(
              s.options ?? [],
              `${filterLabel}`,
              filterTag
            )
          );
          break;
        case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
          filters.push(
            buildSingleChoiceFilter(
              s.options ?? [],
              `${filterLabel}`,
              filterTag
            )
          );
          break;
        case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
        case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST: {
          const linkedList = linkedLists.find((l) => l.id === s.list_id);
          filters.push(
            buildListItemsFilter(
              linkedLists,
              s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
                ? `${filterTag}__id`
                : filterTag,
              linkedList?.id ?? "",
              s.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST,
              `${filterLabel}`
            )
          );
          break;
        }
        default:
          break;
      }
    });
  filters = filters.map((filter) => {
    const shouldDisplayed =
      isListAttrInDashboardFilter(filter.tag, dashboard) ||
      params[FILTER_TAG.MORE_FILTER]?.includes(filter.tag);
    return { ...filter, isSecondaryFilter: !shouldDisplayed };
  });
  return filters;
};
const buildWorkflowsFilter = (
  workflows: { id: string; name: string }[]
): IFilter => {
  const lang = getLangObject();
  const workflowsOptions = workflows.map((w) => ({
    label: w.name,
    key: w.id,
  }));
  return buildMultipleChoiceFilter(
    workflowsOptions,
    lang.components.filters.workflows,
    "workflows"
  );
};

const buildListItemsFilter = (
  allLists: IList[],
  tag: string,
  listId: string,
  singleSelection: boolean = false,
  label?: string
): IFilter => {
  const list = _.find(allLists, { id: listId });
  const opts = _.uniqBy(
    _.concat(
      _.map(list?.items, (i) => ({ key: i._id, label: i._name })),
      (list as any)?.options ?? []
    ),
    "key"
  );
  return buildListItemFilter(
    opts,
    tag,
    listId,
    label ?? list?.name ?? "",
    [],
    singleSelection
  );
};
const buildCustomFilter = (dashboard: IDashboard): IFilter[] => {
  const regex = /\$custom_filter:(.*?)\$/g;
  let customFiltersTags: string[] = [];
  dashboard.kpis.forEach((kpi) => {
    const match = kpi.query?.match(regex);
    if (match) {
      match.forEach((m) => {
        let tag = m.replace("$custom_filter:", "").replace("$", "");
        tag = tag.includes(".") ? tag.split(".")[1] : tag;
        defaultFilterTags.push(tag);
        customFiltersTags.push(tag);
      });
    }
  });
  customFiltersTags = _.uniq(customFiltersTags);
  return customFiltersTags.map((tag) => {
    const label = tag
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return buildTextFilter(label, tag);
  });
};
export const buildListsFilter = (lists: IList[]): IFilter => {
  const lang = getLangObject();
  const listsOptions = lists.map((l) => ({
    label: l.name,
    key: l.id,
  }));
  return buildMultipleChoiceFilter(
    listsOptions,
    lang.components.filters.lists,
    "lists"
  );
};

const buildActionCodeFilter = (): IFilter => {
  const lang = getLangObject();
  const actionNameOptions = [
    ...Object.keys(ACTION_CODE).map((key) => (ACTION_CODE as any)[key]),
    ...["CHECK_IN", "CHECK_OUT", "EDIT_GPS"],
  ].map((key) => ({
    label: key,
    key: key,
  }));
  return buildMultipleChoiceFilter(
    actionNameOptions,
    lang.components.filters.actionCode,
    FILTER_TAG.ACTION_CODE
  );
};

const buildOptimizationFilters = (
  optimization: ITableOptimization,
  level: string | undefined,
  teams: ITeamSelector[],
  metaHierarchy: IMetaHierarchyDependency
) => {
  let options = optimization.options
    ? optimization.options
    : optimization.options2;
  if (!options) {
    // no options to be returned => no additionnal filters
    return [];
  }
  const dimensionTextNumber = optimization.options ? "1" : "2";
  if (level && level !== "" && level !== "None") {
    // correct the options if they are binded to a team level
    const metaLevel = Object.keys(metaHierarchy).find(
      (h) => metaHierarchy[h].level_type_name === level
    );
    if (metaLevel) {
      options = Object.keys(
        teams.reduce((acc, curr) => {
          acc[curr[metaLevel]] = true;
          return acc;
        }, {})
      );
    }
  }
  const optimizationOptions = options.map((o) => ({
    key: o.split(" ").join("_").toLowerCase(),
    label: o.split("_").join(" "),
  }));
  return buildSingleChoiceFilter(
    optimizationOptions,
    `${_.capitalize(
      optimization[`dimension${dimensionTextNumber === "2" ? "2" : ""}`]
        ?.split("_")
        .join(" ")
    )}`,
    `optimization_filter_dimension${dimensionTextNumber}`,
    optimizationOptions[0] ? [optimizationOptions[0].key] : []
  );
};
export const getQueryFiltersForKpi = (
  kpi: IKPI,
  client: IClient,
  objects: {
    workflows?: { id: string; name: string }[];
    lists?: IList[];
    teams?: ITeamSelector[];
    users?: IMobileUser[];
    activities?: IActivity[];
  }
) => {
  const query = {};
  const filters = ["workflows", "activities", "teams", "users", "lists"];
  filters.forEach((filter) => {
    if (isFilterInKpi(filter as any, kpi)) {
      query[filter] = getSample((objects[filter] || []).map((t: any) => t.id));
    }
  });
  if (isFilterInKpi("date", kpi)) {
    const { startDate: start, endDate: end } = getStartAndEndDateFromDateType(
      client.default_date_filter ?? DEFAULT_DATE_FILTER.LAST_7_DAYS
    );
    query["_date"] = {
      startDate: start,
      endDate: end,
      dateType: client.default_date_filter ?? DEFAULT_DATE_FILTER.LAST_7_DAYS,
    };
  }
  return query;

  function getSample(items: string[]) {
    return _.sampleSize(items, _.random(items.length));
  }
};
