import {
  CUSTOM_FIELD_TYPE,
  DETAILED_ACTION_TYPE,
  DETAILED_OBJECT_CATEGORY,
  IActivityReport as IBEActivityReport,
  SOURCE,
} from "fieldpro-tools";
import _ from "lodash";

import {
  IUpdateActivityReportActionFunc,
  IUpdateWorkflowReportActionFunc,
  IUploadActivityReportFileActionFunc,
} from "containers/workflows/redux/actions";
import {
  ACTIVITY_REPORT_META_KEYS,
  formatUploadFields,
} from "containers/workflows/utils/";
import { IFileInputData } from "hooks/useFormState";
import { formatString } from "lang/utils";
import { getDetailedActionName } from "model/application/ActionCode";
import TLang from "model/application/Lang";
import { ISignedInUser } from "model/entities/User";
import {
  IActivity,
  IActivityReportInTable,
  IWorkflow,
  IWorkflowReportWithStepOverview,
} from "model/entities/Workflow";
import { getFieldByTag } from "utils/formatting/customFieldValues";
import { isEmptyValue } from "utils/isEmptyValue";

import { resolveMetaExpressionInStringInBE } from "../../workflows/utils/metaResolver";
import convertActivityReport from "./convertActivityReport";

interface IHandleEditActivityReport {
  workflow?: IWorkflow;
  workflowReport?: IWorkflowReportWithStepOverview;
  activity: IActivity;
  reportState: IActivityReportInTable;
  filesToUpload: IFileInputData[];
  signedInUser: ISignedInUser;
  updateWorkflowReport: IUpdateWorkflowReportActionFunc;
  updateActivityReport: IUpdateActivityReportActionFunc;
  uploadActivityReportFile: IUploadActivityReportFileActionFunc;
  lang: TLang;
}

const FULL_META_KEYS = [
  ..._.map(ACTIVITY_REPORT_META_KEYS, (k: string) => k),
  ..._.map(ACTIVITY_REPORT_META_KEYS, (k: string) => `_${k}`),
  "_user_id",
  "_user_name",
  "user_name",
  "_user_phone",
  "_user_email",
  "_owners",
  "_completed_source",
  "_updated_source",
];

// NEEDS REFACTO: should be split into smaller functions (+ at least 1 for each action / API call)
const handleEditActivityReport = async ({
  workflow,
  workflowReport,
  activity,
  reportState,
  filesToUpload,
  signedInUser,
  updateWorkflowReport,
  updateActivityReport,
  uploadActivityReportFile,
  lang,
}: IHandleEditActivityReport) => {
  const report = convertActivityReport({
    questionsSchema: activity?.questionnaire?.questions,
    report: reportState,
  }) as IActivityReportInTable;
  const langLabel =
    lang.containers.workflows.subCategories.activityReports.createEditModal;
  if (!workflow) {
    throw new Error(
      formatString(langLabel?.missingWorkflow.title, [report._id])
    );
  }

  if (!workflowReport) {
    throw new Error(
      formatString(langLabel?.missingWorkflowReport.title, [report._id])
    );
  }

  if (!activity) {
    throw new Error(
      formatString(langLabel?.missingActivity.title, [report._id])
    );
  }

  const scope_result = report["_owners"];
  delete report["_owners"];

  const report_title_template = workflow.report_title_template
    ? await resolveMetaExpressionInStringInBE(workflow.report_title_template, {
        workflow_id: workflow.id,
        workflow_report_id: workflowReport.id,
        get_options_label: true,
      })
    : "";

  const displayed_name = activity.report_title_template
    ? await resolveMetaExpressionInStringInBE(activity.report_title_template, {
        // activity: activity as IBEActivity,
        activity_report: convertActivityReport({
          questionsSchema: activity?.questionnaire?.questions,
          report: reportState,
          simpleMatrixFormat: true,
        }) as IBEActivityReport,
        get_options_label: true,
      })
    : "";

  report["_displayed_name"] = displayed_name;
  const editedWorkflowReport = {
    ...workflowReport,
    name: report_title_template,
    updated_locally_at: new Date().toISOString(),
    updated_by: signedInUser.id,
    updated_source: SOURCE.WEB,
    step_overview: _.compact(
      _.map(workflow.steps, (step) => {
        const stepOverview = _.find(workflowReport?.step_overview, {
          step_id: step.id,
        });

        if (!stepOverview) {
          return null;
        }

        return {
          step_id: step.id,
          step_scope: step.scope,
          step_status: stepOverview.step_status,
          scope_result: scope_result ?? [],
        };
      })
    ),
  };

  Object.keys(editedWorkflowReport).forEach((key) => {
    if (!editedWorkflowReport[key] && key !== "version")
      delete editedWorkflowReport[key];
  });

  delete (editedWorkflowReport as any).shared_globally;
  delete (editedWorkflowReport as any).steps;

  // .then() changed into async/await syntax from here on
  const newWorkflowReport = (
    (await updateWorkflowReport(editedWorkflowReport)) as any
  )?.workflow_report;

  const newReport = _.reduce(
    ACTIVITY_REPORT_META_KEYS,
    (o: any, key: string) => {
      if (
        report.hasOwnProperty(`_${key}`) &&
        report[`_${key}`] !== null &&
        report[`_${key}`] !== undefined
      )
        o[key] = report[`_${key}`];
      return o;
    },
    {}
  );

  const customFieldKeys = _.filter(
    _.keys(report),
    (key) => !FULL_META_KEYS.map((fmk) => fmk).includes(key)
  );

  const customFieldValues = _.compact(
    _.map(customFieldKeys, (key) => {
      const value = report[key];
      if (isEmptyValue(value)) {
        return null;
      }
      return {
        key: key,
        value,
      };
    })
  );

  // UPLOAD FILES
  const fileData = await uploadFormFiles({
    filesToUpload,
    workflow,
    workflowReport,
    activity,
    activityReportId: newReport.id,
    uploadFile: uploadActivityReportFile,
  });

  if (fileData) {
    formatUploadFields(fileData, customFieldValues);
  }

  const finalReport = {
    ...newReport,
    ...(newWorkflowReport && {
      activity_version: activity.version,
      updated_at: new Date(newWorkflowReport.updated_at),
    }),
    custom_field_values: customFieldValues,
  };

  await updateActivityReport(finalReport);
};

interface IUploadFormFiles {
  filesToUpload: IFileInputData[];
  workflow?: IWorkflow;
  workflowReport?: IWorkflowReportWithStepOverview;
  activity?: IActivity;
  activityReportId?: string;
  uploadFile: IUploadActivityReportFileActionFunc;
  customActionName?: string;
}

export const uploadFormFiles = async ({
  filesToUpload,
  workflow,
  workflowReport,
  activity,
  activityReportId,
  uploadFile,
  customActionName,
}: IUploadFormFiles) => {
  if (_.isEmpty(filesToUpload)) {
    return null;
  }

  const questions = activity?.questionnaire?.questions;

  const metas = [];
  const files = [];

  for (const fileData of filesToUpload) {
    const { tag, file, fileName } = fileData || {};

    if (file) {
      const meta = {
        tag,
        type: questions
          ? getFieldByTag(questions, tag)?.type
          : CUSTOM_FIELD_TYPE.PICTURE,
        workflow_id: workflow?.id,
        workflow_report_id: workflowReport?.id,
        step_id: workflow?.steps[0]?.id,
        activity_report_id: activityReportId,
        activity_id: activity?.id,
        filename: fileName,
      };

      files.push(fileData);
      metas.push(meta);
    }
  }

  if (_.isEmpty(files)) {
    return null;
  }

  try {
    const actionName =
      customActionName ??
      getDetailedActionName(
        DETAILED_ACTION_TYPE.UPLOAD_FILE_FOR,
        DETAILED_OBJECT_CATEGORY.ACTIVITY_REPORT
      );
    return uploadFile(actionName, files, metas);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export default handleEditActivityReport;
