import { useEffect, useRef, useState } from "react";

import { Box, Fade, makeStyles } from "@material-ui/core";
import {
  CalendarEventStatus,
  CalendarEventType,
  computeEventsStatus,
  EventChangeScope,
  IDSource,
  IVisitEvent,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";

import CustomDialogBulkOperation from "components/Dialog/CustomDialogBulkOperation";
import { getQuery } from "components/Filter/Filter.utils";
import { RequiredByKey } from "components/MetaExpressionWrapper";
import {
  getSignedInUser,
  mobileUserTeamMatesComposedSelector,
} from "containers/authentication/redux/selector";
import { useActions, useCalendarRange, useTranslations } from "hooks";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";
import { IFilter } from "model/application/Filter";
import { LANG_ACTIONS, SUB_CATEGORIES } from "model/application/Lang";
import { TViewMode } from "model/application/modal/CreateEditModal";

import CalendarContainerHeader from "./CalendarContainerHeader";
import AddVisitDialog from "./components/AddVisitDialog";
import Calendar, { ICalendarProps } from "./components/Calendar/Calendar";
import CalendarDayView, {
  TCalendarViewProps,
} from "./components/Calendar/CalendarDayView";
import { isRepeatingEvent } from "./components/Calendar/utils/reapeatingEventsUtils";
import { updateSelectedFieldUserAction } from "./redux/actionCreators";
import {
  approveOrDeclineEventsActionFunction,
  createEventsActionFunction,
  deleteEventsActionFunction,
  fetchEventsActionFunction,
  updateEventsActionFunction,
} from "./redux/actions";
import {
  getEventRequiresApproval,
  getEvents,
  getIsFetchingEvents,
  getIsUserAManager,
  getSelectedFieldUsers,
} from "./redux/selectors";
import { TNewEventPayload } from "./redux/types";
import RepeatingEventConfirmationDialog from "./RepeatingEventConfirmationDialog";
import { styles } from "./styles";
import { CalendarContainerSubcategoryManager } from "./SubcategoryManager";
import { TCalendarFilterObject, TCommonProps, TInitEvent } from "./types";
import { computeNewEvent } from "./utils/computeNewEvent";
import { getCsvBodyTemplate, parseEventsCSV } from "./utils/csvTools";
import { getCurrentFieldUser } from "./utils/getCurrentFieldUser";
import { getEventPlaceHolders } from "./utils/getEventPlaceHolders";
import { getEventsToDisplay } from "./utils/getEventsToDisplay";
import { CALENDAR_FILTERS, prepareFilters } from "./utils/prepareFilters";

const useStyles = makeStyles(styles as any);

const CalendarContainer = () => {
  const { moment } = useMomentTimeZone({
    cleanTimeZoneOnUnmount: true,
  });
  const scrollAnchor = useRef<HTMLDivElement>(null);
  const draggable = true;
  const classes = useStyles();
  const lang = useTranslations();
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const {
    calendarRange: { date },
    setCalendarMode,
  } = useCalendarRange();
  const startDate = moment(date).toDate();
  const dispatch = useDispatch();
  /* ------------------------------ REDUX ACTIONS ----------------------------- */
  const [
    createEventsAction,
    updateEventsAction,
    fetchEventsAction,
    deleteEventsAction,
    approveOrDeclineEventsAction,
  ] = useActions([
    createEventsActionFunction,
    updateEventsActionFunction,
    fetchEventsActionFunction,
    deleteEventsActionFunction,
    approveOrDeclineEventsActionFunction,
  ]);

  /* -------------------------------- SELECTORS ------------------------------- */
  const isFetchingEvents = useSelector(getIsFetchingEvents);
  const eventsStored = useSelector(getEvents);
  const isManager = useSelector(getIsUserAManager);
  const eventsNeedApproval = useSelector(getEventRequiresApproval);
  const signedInUser = useSelector(getSignedInUser);
  const allMobileTeamMates = useSelector(mobileUserTeamMatesComposedSelector);
  const selectedFieldUsers = useSelector(getSelectedFieldUsers);
  const linkedMobileUser = _.find(allMobileTeamMates, {
    email: signedInUser.email,
  });

  /* ------------------------------ LOCAL STATE ------------------------------ */
  const [newEventObject, setNewEventObject] = useState<
    Partial<TCalendarEvent> | undefined
  >(undefined);
  const [repeatDialogAction, setRepeatDialogAction] = useState<
    "DELETE" | "EDIT" | undefined
  >();
  const [openRepeatDialog, setOpenRepeatDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogViewMode, setDialogViewmode] = useState<TViewMode>("CREATE");
  const [events, setEvents] = useState<TCalendarEvent[]>(eventsStored ?? []);
  const [currentDate, setCurrentDate] = useState(moment(date));
  const [filterQuery, setFilterQuery] = useState<TCalendarFilterObject>({
    [CALENDAR_FILTERS.USERS]: selectedFieldUsers ? selectedFieldUsers : [],
  });
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const { currentFieldUser, allSelectedUsers } = getCurrentFieldUser(
    linkedMobileUser,
    isManager,
    filterQuery
  );

  const selectedMultipleUsers = _.size(allSelectedUsers) > 1;

  const [selectedEvent, setSelectedEvent] = useState<
    TCalendarEvent | undefined
  >(undefined);
  const [initEvent, setInitEvent] = useState<TInitEvent>({
    assigned_to: currentFieldUser,
    start_time: moment().toDate(),
    status: CalendarEventStatus.PLANNED,
  });
  const filtersPrepared = prepareFilters({
    mobileUsers: allMobileTeamMates,
    lang,
    filterQuery,
    displayFieldUserFilter: isManager,
  });

  const allowCreation = !_.isEmpty(currentFieldUser);
  const allowDisplayingEvents =
    !isFetchingEvents && !_.isUndefined(currentFieldUser);

  async function handlefetchEvents() {
    if (!currentFieldUser) return;
    const result = await fetchEventsAction({
      start_time: moment(currentDate).startOf("year").toDate(),
      end_time: moment(currentDate).endOf("year").toDate(),
      mobile_user_ids: allSelectedUsers,
    });
    if (!result) setEvents([]);
  }

  async function onReorGanizeEvents(reorganizedEvents: TCalendarEvent[]) {
    const changedEvents = _.filter(reorganizedEvents, (event) => {
      const currentEvent = _.find(events, { id: event.id });
      return !_.isEqual(event, currentEvent);
    });
    setEvents(reorganizedEvents);
    await updateEventsAction(changedEvents, undefined, true);
  }

  function onClickTimeSlot(date: Moment, userId?: string) {
    const start_time = moment(date).toDate();
    setInitEvent({
      ...(initEvent || {}),
      assigned_to: userId || currentFieldUser,
      start_time,
      id: IDSource(),
    });
    setDialogViewmode("CREATE");
    setOpenDialog(true);
  }

  function onAddVisit(value: TCalendarEvent, _name: string) {
    setEvents([value, ...events]);
  }

  async function onChangeFilters(filters: IFilter<any>[]) {
    setFilterQuery(getQuery(filters));
  }

  async function onUpdateFieldUser() {
    if (!currentFieldUser) return;
    setInitEvent({
      ...initEvent,
      start_time: initEvent?.start_time || moment().toDate(),
      assigned_to: currentFieldUser,
    });
    dispatch(updateSelectedFieldUserAction(allSelectedUsers));
  }

  async function onSaveVisit(event: IVisitEvent) {
    const newEvent = {
      ...event,
      type: CalendarEventType.VISIT,
      status: eventsNeedApproval
        ? CalendarEventStatus.PENDING
        : CalendarEventStatus.PLANNED,
    };
    const computedEvent = computeNewEvent(newEvent);
    onAddVisit(computedEvent, "");
    setInitEvent(undefined);
    await createEventsAction([computedEvent as TNewEventPayload]);
  }

  async function handleEditEvent(
    editedEvent: TCalendarEvent,
    scope?: EventChangeScope
  ) {
    const oldEvent = _.find(events, { id: editedEvent.id });
    const oldEvents = events;
    const newVisits = _.concat(
      _.compact(_.without(events, oldEvent)),
      editedEvent
    );
    setEvents(newVisits);
    try {
      await updateEventsAction([editedEvent], scope, true);
    } catch {
      setEvents(oldEvents);
    }
  }

  async function handleDeleteEvent(
    eventToDelete: TCalendarEvent,
    scope?: EventChangeScope
  ) {
    await deleteEventsAction(_.compact([eventToDelete.id]), scope);
  }

  function onClickAddSingleVisit() {
    setOpenDialog(true);
    setDialogViewmode("CREATE");
  }
  function onClickAddMultipleVisits() {
    setIsBulkModalOpen(true);
    setDialogViewmode("CREATE");
  }
  function onChangeDateRange(date: moment.Moment) {
    if (date.year() !== currentDate.year()) {
      setCurrentDate(date);
    }
  }
  function onCloseVisitDialog() {
    setInitEvent({
      assigned_to: currentFieldUser,
      start_time: moment().toDate(),
      status: CalendarEventStatus.PLANNED,
    });
    setOpenDialog(false);
  }
  function onClickEditEvent(e: TCalendarEvent) {
    setInitEvent(e as RequiredByKey<TCalendarEvent, "assigned_to">);
    setOpenDialog(true);
    setDialogViewmode("EDIT");
  }

  function handleConfirmDialog(scope: EventChangeScope) {
    switch (repeatDialogAction) {
      case "DELETE":
        handleDeleteEvent(selectedEvent as TCalendarEvent, scope);
        break;
      case "EDIT":
        handleEditEvent(selectedEvent as TCalendarEvent, scope);
        break;
      default:
        break;
    }
    setSelectedEvent(undefined);
    setOpenRepeatDialog(false);
    setOpenDialog(false);
  }

  /* ------------------------------- USE EFFECTS ------------------------------ */
  useEffect(() => {
    if (isManager || allSelectedUsers) {
      handlefetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, JSON.stringify(allSelectedUsers)]);

  useEffect(() => {
    onUpdateFieldUser();

    if (selectedMultipleUsers) {
      setCalendarMode("day");
    } else {
      setCalendarMode("week");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldUser, selectedMultipleUsers]);

  useEffect(() => {
    setEvents(eventsStored ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(eventsStored)]);

  type TShareCalendarProps = TCommonProps<ICalendarProps, TCalendarViewProps>;

  const props: TShareCalendarProps = {
    activeBoardItems:
      dialogViewMode === "EDIT" && initEvent?.id ? [initEvent.id] : [],
    defaultEvents: _.filter(
      computeEventsStatus(
        getEventsToDisplay({
          allowDisplayingEvents,
          events,
          filterQuery,
        }).filter(
          (e) => e.assigned_to && allSelectedUsers.includes(e.assigned_to)
        )
      )
      // TODO: FIXME
      // (event) => {
      //   return eventIsWithinAllowedHours({
      //     event,
      //     maxEventEndTime,
      //     minEventStartTime,
      //     moment,
      //   });
      // }
    ),
    eventPlaceHolders: getEventPlaceHolders({
      newEventObject,
      dialogViewMode,
      allowCreation,
      openDialog,
    }),
    onChange: onReorGanizeEvents,
    onClickApproveEvent: (e) => {
      approveOrDeclineEventsAction(e, true);
    },
    onClickDeclineEvent: (e) => {
      approveOrDeclineEventsAction(e, false);
    },
    onClickDeleteEvent: (e) => {
      if (isRepeatingEvent(e)) {
        setSelectedEvent(e);
        setRepeatDialogAction("DELETE");
        setOpenRepeatDialog(true);
      } else {
        handleDeleteEvent(e);
      }
    },
    onEditEvent: onClickEditEvent,
    onClickTimeSlot,
    startDate: startDate.toString(),
  };

  return (
    <CalendarContainerSubcategoryManager>
      <Box>
        <Box className={classes.root}>
          <CalendarContainerHeader
            onChangeDateRange={onChangeDateRange}
            onClickAddMultipleVisits={onClickAddMultipleVisits}
            onClickAddSingleVisit={onClickAddSingleVisit}
            currentFieldUser={currentFieldUser}
            isManager={isManager}
            filters={filtersPrepared}
            onChangeFilters={onChangeFilters}
          />

          <Box position={"relative"} overflow={"hidden"}>
            <Fade in={allowCreation && openDialog}>
              <Box
                className={classes.addVisitDialogWrapper}
                height={calendarContainerRef.current?.clientHeight}
              >
                <AddVisitDialog
                  key={initEvent?.id}
                  onAddVisit={onSaveVisit}
                  onEditEvent={(e) => {
                    if (isRepeatingEvent(e)) {
                      setSelectedEvent(e);
                      setRepeatDialogAction("EDIT");
                      setOpenRepeatDialog(true);
                    } else {
                      handleEditEvent(e);
                    }
                  }}
                  handleClose={onCloseVisitDialog}
                  events={events}
                  initEvent={initEvent as TNewEventPayload}
                  viewMode={dialogViewMode}
                  onChange={(event) => setNewEventObject(event)}
                />
              </Box>
            </Fade>

            <div ref={calendarContainerRef}>
              {selectedMultipleUsers ? (
                <CalendarDayView {...props} selectedUsers={allSelectedUsers} />
              ) : (
                <Calendar
                  {...props}
                  scrollAnchor={scrollAnchor}
                  draggable={draggable}
                  width="100%"
                  minWidth="600px"
                  height={`calc(100vh - 220px)`}
                />
              )}
            </div>
          </Box>
        </Box>

        <CustomDialogBulkOperation
          lang={lang}
          csvBodyTemplate={getCsvBodyTemplate}
          modalNameInLang="modalNameInLang"
          onClose={() => {
            setIsBulkModalOpen(false);
          }}
          onAction={async (e) => {
            await createEventsAction(e as TNewEventPayload[], {
              runInBackground: _.size(e) > 100,
            });
          }}
          operationType={LANG_ACTIONS.UPLOAD}
          parseCSV={(lines) => {
            return parseEventsCSV(lines, lang);
          }}
          subCategoryInLang={SUB_CATEGORIES.CALENDAR_EVENT}
          isOpen={isBulkModalOpen}
        />
        <RepeatingEventConfirmationDialog
          open={openRepeatDialog}
          onClose={() => {
            setOpenRepeatDialog(false);
          }}
          onConfirm={handleConfirmDialog}
          viewMode={repeatDialogAction}
        />
      </Box>
    </CalendarContainerSubcategoryManager>
  );
};

export default CalendarContainer;
